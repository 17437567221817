<template>
  <div class="px-10" data-testid="site-data-extract-page">
    <Breadcrumbs />

    <v-row>
      <v-col sm="6" class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">TRIAL {{ trialNumber }}</span>
        </div>

        <h4 class="page-title" data-testid="trial-title">
          Data extract: Site {{ trialSiteNumber }}
        </h4>
      </v-col>
    </v-row>

    <BannerButton @click="handleBlankFormClick">
      <template #image>
        <ImgBlankForm />
      </template>
      <h4 class="mb-4">Blank form</h4>
      <div>Download a blank form</div>
    </BannerButton>

    <BannerButton @click="handleArchiveClick">
      <template #image>
        <ImgDataArchive />
      </template>
      <h4 class="mb-4">Data archive</h4>
      <div>Request and download archive</div>
    </BannerButton>

    <BannerButton @click="handleReportsClick">
      <template #image>
        <ImgReport />
      </template>
      <h4 class="mb-4">Reports</h4>
      <div>Request and download reports</div>
    </BannerButton>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/layout/Breadcrumbs'

import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import ImgDataArchive from '@/components/images/ImgDataArchive'
import ImgReport from '@/components/images/ImgReport'
import BannerButton from '@/components/BannerButton'
import ImgBlankForm from '@/components/images/ImgBlankForm'

export default {
  name: 'DataExtract',
  mixins: [trialSettingsGettersMixin],
  components: {
    ImgBlankForm,
    BannerButton,
    ImgReport,
    ImgDataArchive,
    Breadcrumbs,
  },
  methods: {
    handleBlankFormClick() {
      this.$router.push({
        name: 'siteBlankForm',
      })
    },
    handleArchiveClick() {
      this.$router.push({
        name: 'archive',
      })
    },
    handleReportsClick() {
      this.$router.push({
        name: 'reports',
      })
    },
  },
}
</script>

<style scoped></style>
