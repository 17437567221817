<template>
  <v-card elevation="1" class="my-5" @click="click">
    <v-row class="align-center">
      <v-col class="shrink image-wrapper d-flex align-center justify-center">
        <slot name="image" />
      </v-col>
      <v-col>
        <slot />
      </v-col>
      <v-col class="text-right pr-15">
        <v-btn color="secondary" rounded depressed>Continue</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'BannerButton',
  methods: {
    click(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style scoped lang="scss">
.image-wrapper {
  padding-left: 71px;
  padding-right: 67px;
}
</style>
