<template>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="140" height="140" rx="70" fill="#89C1EB" />
    <rect x="39.5" y="30.5" width="61" height="79" rx="3.5" stroke="#001965" />
  </svg>
</template>

<script>
export default {
  name: 'ImgBlankForm',
}
</script>

<style scoped></style>
