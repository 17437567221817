<template>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="140" height="140" rx="70" fill="#89C1EB" />
    <rect x="39.5" y="29.5" width="61" height="79" rx="3.5" stroke="#001965" />
    <path
      d="M47.5249 43.1177H76.9749"
      stroke="#001965"
      stroke-linecap="round"
    />
    <path
      d="M47.5249 53.3137H63.0249"
      stroke="#001965"
      stroke-linecap="round"
    />
    <path
      d="M46.75 98.8039C48.5835 97.2253 50.4434 95.6518 52.5246 94.4287C53.8784 93.6331 55.991 92.8207 57.4913 93.684C59.2738 94.7097 60.6157 96.084 62.6824 96.6008C64.7834 97.1262 66.8126 96.2276 68.5841 95.1347C69.4162 94.6212 69.8296 94.3455 70.3868 95.282C70.85 96.0607 71.0972 96.6689 71.9276 97.0585C74.1328 98.0931 76.6504 97.2082 78.525 95.8716"
      stroke="#001965"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgReport',
}
</script>
